.landing-page {
  min-height: 100vh;
  background-color: #f8f9fa;
}

.landing-header {
  background-color: #001529;
  padding: 1rem 2rem;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.landing-header nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
}

.auth-buttons {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.login-btn {
  padding: 0.5rem 1.5rem;
  background-color: transparent;
  color: white;
  border: 1px solid #1890ff;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s;
}

.login-btn:hover {
  background-color: #1890ff;
  border-color: #1890ff;
}

.register-btn {
  padding: 0.5rem 1.5rem;
  background-color: #1890ff;
  color: white;
  border: 1px solid #1890ff;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s;
}

.register-btn:hover {
  background-color: #40a9ff;
  border-color: #40a9ff;
}

main {
  max-width: 1200px;
  margin: 0 auto;
  padding: 6rem 2rem 2rem;
}

.hero-section {
  text-align: center;
  padding: 4rem 0;
}

.hero-section h1 {
  font-size: 3rem;
  color: #1a1a1a;
  margin-bottom: 1.5rem;
  background: linear-gradient(120deg, #1890ff, #722ed1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-section p {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 2rem;
}

.hero-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-bottom: 3rem;
}

.hero-image {
  max-width: 800px;
  margin: 0 auto;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  border-radius: 8px;
  overflow: hidden;
}

.hero-image img {
  width: 100%;
  height: auto;
}

.features-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 4rem 0;
}

.feature-card {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.05);
  transition: transform 0.3s;
}

.feature-card:hover {
  transform: translateY(-5px);
}

.feature-icon {
  font-size: 2.5rem;
  color: #1890ff;
  margin-bottom: 1.5rem;
}

.feature-card h3 {
  color: #1a1a1a;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.feature-card p {
  color: #666;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.feature-demo {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 1rem;
  margin-top: 1.5rem;
}

.chat-demo .message {
  padding: 0.8rem;
  border-radius: 8px;
  margin-bottom: 0.8rem;
}

.chat-demo .user {
  background: #e6f7ff;
  margin-left: 20%;
}

.chat-demo .bot {
  background: #f6f6f6;
  margin-right: 20%;
}

.chart-demo img, .bot-demo img {
  width: 100%;
  border-radius: 4px;
}

.workflow-section {
  padding: 4rem 0;
  text-align: center;
}

.workflow-section h2 {
  margin-bottom: 3rem;
  color: #1a1a1a;
}

.workflow-steps {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.step {
  padding: 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.05);
}

.step-number {
  width: 40px;
  height: 40px;
  background: #1890ff;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem;
  font-weight: bold;
}

.step h4 {
  color: #1a1a1a;
  margin-bottom: 1rem;
}

.step p {
  color: #666;
}

.cta-section {
  text-align: center;
  padding: 4rem 0;
  background: linear-gradient(120deg, #f0f5ff, #e6f7ff);
  border-radius: 12px;
  margin: 2rem 0;
}

.cta-section h2 {
  margin-bottom: 2rem;
  color: #1a1a1a;
}

footer {
  background-color: #001529;
  padding: 2rem;
  text-align: center;
  color: #fff;
}

@media (max-width: 768px) {
  .hero-section h1 {
    font-size: 2rem;
  }
  
  .hero-section p {
    font-size: 1rem;
  }
  
  .hero-buttons {
    flex-direction: column;
    align-items: center;
  }
  
  .features-section {
    grid-template-columns: 1fr;
  }
  
  .workflow-steps {
    grid-template-columns: 1fr;
  }

  .auth-buttons {
    gap: 0.5rem;
  }

  .login-btn, .register-btn {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }
}
