body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; /* Prevent horizontal scroll on body */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .ant-drawer .ant-drawer-content {
    background-color: #001529;
  }

  .ant-drawer .ant-drawer-body {
    padding: 0;
  }

  .ant-menu-dark.ant-menu-inline .ant-menu-item {
    height: 48px;
    line-height: 48px;
    margin: 4px 0;
  }

  .ant-card {
    border-radius: 8px;
  }

  .ant-layout-header {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 0 8px !important; /* Reduce padding on mobile */
    width: 100% !important;
    max-width: 100vw;
    box-sizing: border-box;
  }

  .ant-layout {
    width: 100% !important;
    max-width: 100vw;
    overflow-x: hidden;
  }

  .ant-layout-content {
    margin: 8px 8px !important;
    padding: 12px !important;
    width: auto !important;
    box-sizing: border-box;
    overflow-x: hidden;
  }

  /* Ensure tables and other wide content don't cause horizontal scroll */
  .ant-table-wrapper {
    width: 100%;
    overflow-x: auto;
  }
}

/* Sidebar Styles */
.ant-layout-sider {
  transition: all 0.2s ease-in-out;
}

.ant-menu-dark .ant-menu-item {
  margin: 4px 8px !important;
  border-radius: 4px;
}

.ant-menu-dark .ant-menu-item:hover {
  background-color: #1890ff !important;
}

.ant-menu-dark .ant-menu-item-selected {
  background-color: #1890ff !important;
}

/* Header Styles */
.ant-layout-header {
  transition: padding 0.2s ease-in-out;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* User Dropdown Menu */
.ant-dropdown .ant-dropdown-menu {
  padding: 4px;
  border-radius: 8px;
}

.ant-dropdown .ant-dropdown-menu-item {
  padding: 8px 12px;
  border-radius: 4px;
  margin: 2px 0;
}

.ant-dropdown .ant-dropdown-menu-item:hover {
  background-color: #f5f5f5;
}

.ant-dropdown .ant-dropdown-menu-item-danger {
  color: #ff4d4f;
}

.ant-dropdown .ant-dropdown-menu-item-danger:hover {
  background-color: #fff1f0;
  color: #ff4d4f;
}

.ant-dropdown .anticon {
  font-size: 16px;
  margin-right: 8px;
}

/* User Button */
.ant-btn-text.user-button {
  transition: all 0.3s;
}

.ant-btn-text.user-button:hover {
  background-color: rgba(0, 0, 0, 0.06);
}

.ant-btn-text.user-button:active {
  background-color: rgba(0, 0, 0, 0.1);
}

/* Improve touch targets on mobile */
.ant-menu-item, .ant-dropdown-menu-item, .ant-btn {
  touch-action: manipulation;
}

/* Scrollbar styling */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Drawer Animation */
.ant-drawer.ant-drawer-open .ant-drawer-content-wrapper {
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}

/* Menu Item Icons */
.ant-menu-item .anticon {
  font-size: 18px;
  vertical-align: middle;
}

/* Divider in dropdown */
.ant-dropdown-menu-item-divider {
  margin: 4px 0;
  background-color: #f0f0f0;
}

/* Ensure layout container doesn't overflow */
.ant-layout {
  min-height: 100vh;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
}

/* Ensure content area is properly contained */
.ant-layout-content {
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

/* Custom style to remove border-radius for specific modal */
.no-border-radius-modal .ant-modal-content {
  border-radius: 0 !important;
}

/* Custom style for menu button in sidebar */
.sidebar-menu-button {
  border-radius: 50%;
  transition: background-color 0.3s;
}

.dark-theme .sidebar-menu-button {
  background-color: #ffffff; /* White background for dark theme */
  color: #001529; /* Dark color for icon */
}

.light-theme .sidebar-menu-button {
  background-color: #000000; /* Black background for light theme */
  color: #ffffff; /* White color for icon */
}

.sidebar-menu-button:hover {
  background-color: #f0f0f0; /* Light grey on hover */
}

/* Ensure icon is white when in dark sidebar */
.ant-layout-sider .sidebar-menu-button {
  color: #ffffff; /* White color for icon in dark sidebar */
}
